import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "../../assets/music.sass"
import "../../assets/animations.css"

const Musician = () => (
  <div className="music-page-container">
    
    <Seo title="The Musician" />

    <div className="topic-title tracking-in-expand">
      <h1>MUSICA</h1>
    </div>

    <div className="soundclound-link-container">
      
      <div className="soundcloud-link">
      <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1361980219&color=%23e42ad7&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>
      </div>
      
      <div className="soundcloud-link">
      <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1283935699&color=%23e42ad7&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1142987917&color=%23e42ad7&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1159175812&color=%236910d4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1239525511&color=%2324edca&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1219653472&color=%23844454&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
       <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1206924238&color=%23e42ad7&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1234774885&color=%232a17ce&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1102525498&color=%23640c2c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1098807988&color=%23640c2c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
      <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1158934582&color=%23503414&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
      <div className="soundcloud-link">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1108997368&color=%23640c2c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>

    </div>

    <div>
      
      <div className="saying-whatever">
        <p>I have over 100 Spotify playlist.</p>
        <p>Why? idk</p>
      </div>

      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/64Ynrxn0PAJ4btgDqOxueY" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/5SUtnF8ZUBYKZJn4JOQMBx" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
      <iframe src="https://open.spotify.com/embed/playlist/6fJzPmJQSSHSMKCsBLQdqw?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/7dqm9Un5ZhQnrTGaFU3wdE" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/6YFUkPOCx5tZfeAW3KDzSK?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/7d8C8wRUCKlsveE41tG2co" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/1Eg2iFJZwNRWmBC1U3xPIY" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/7uOqRTt2WrmosbUYWnCOnC" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
      <div className="spotify-playlist-container">
        <iframe src="https://open.spotify.com/embed/playlist/6I5ReR5LkNUjsTBycmV726?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </div>

    </div>

    

  </div>
)

export default Musician
